import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import Img from "gatsby-image";
import SEO from "../components/seo";
import ReactMarkdown from "react-markdown/with-html";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import ContactForm from "../components/forms/contact";

const ContactModal = ({ show, handleClose, title }) => (
  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Contact me regarding {title}</Modal.Title>
    </Modal.Header>
    <div className="px-5 py-5">
      <ContactForm referrer={title} />
    </div>
  </Modal>
);
export const AdasProPageTemplate = ({ title, titleSEO, headerImage, content }) => {
  const [show, setShow] = useState(false);

  return (
    <Layout>
      <SEO title={titleSEO} />
      <ContactModal show={show} handleClose={() => setShow(false)} title={title} />
      <Row
        className="d-flex flex-row justify-content-center align-items-center mx-0"
        style={{ height: "calc(100vh - 54px)" }}
      >
        <Col xs={12} lg={8}>
          <PreviewCompatibleImage imageInfo={{ image: headerImage, alt: "image" }} />
        </Col>
        <Col xs={12} lg={4} className="text-center">
          <h1>{title}</h1>
          <p>
            <ReactMarkdown source={content} escapeHtml={false} linkTarget="_blank" />
          </p>
          <Button onClick={() => setShow(!show)}>Contact Me</Button>
          <p className="pt-3">
            Or call: <a href="tel:2096020734">209-602-0734</a>
          </p>
        </Col>
      </Row>
    </Layout>
  );
};

const AdasProPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <AdasProPageTemplate
        title={frontmatter.title}
        titleSEO={frontmatter.titleSEO}
        headerImage={frontmatter.headerImage}
        content={frontmatter.content}
      />
    </Layout>
  );
};

export default AdasProPage;

export const pageQuery = graphql`
  query AdasProPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "adas-pro-page" } }) {
      frontmatter {
        title
        titleSEO
        content
        headerImage {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
